import React from "react";
import { graphql } from "gatsby";
import PortableText from "react-portable-text";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import List from "../components/terra/list";
import RegenerativePledge from "../components/common/regenerativePledge";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import Seo from "../components/seo";
import Faqs from "../components/home/faq";

export default function Terra({
  data: { sanityTerraPage, sanitySitesettings },
}) {
  let seo;
  let hero;
  let breadcrumb;
  let regenerative;
  let faq;

  sanityTerraPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />
      <Hero heroSection={hero} h2Size="text-3xl sm:text-[60px]" h2P="pb-7" />
      <Logo logo={sanitySitesettings?.logo} />

      {sanityTerraPage?._rawIntro && (
        <div className="tb-container">
          <div className="w-[90%] mx-auto mb-[25px] p-5 show">
            <PortableText
              content={sanityTerraPage?._rawIntro}
              className="portable-editor text-center font-bold text-[20px] leading-[32px] text-[#323a44] block-content max-w-none"
            />
          </div>
        </div>
      )}

      {sanityTerraPage?.terraList && (
        <div className="tb-container">
          <List data={sanityTerraPage?.terraList} />
        </div>
      )}

      {sanityTerraPage?._rawContent && (
        <div className="tb-container">
          <div className="w-[90%] mx-auto mb-[25px] p-5 show">
            <PortableText
              content={sanityTerraPage?._rawContent}
              className="portable-editor text-center font-bold text-base leading-[26px] text-[#323a44] block-content max-w-none"
            />
          </div>
        </div>
      )}
      {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}

      {breadcrumb && <BreadCrumb path={breadcrumb} />}

      <Instagram />
      {regenerative && (
        <div>
          <RegenerativePledge
            data={regenerative}
            min_h="min-h-[72vh]"
            pb="pb-[80px]"
            px="px-[10px]"
          />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityTerraPage {
      pageBuilder {
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }
      _rawIntro
      _rawContent
      terraList {
        heading
        list {
          content
          svg
        }
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
